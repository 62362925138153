export default {
    address: "紹興南街22號",
    googleSrc: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3614.8514565980154!2d121.52129231598045!3d25.03911464423816!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3442a9770f0e95ad%3A0xf785ac1d80f2eaf2!2zMTAw5Y-w5YyX5biC5Lit5q2j5Y2A57S56IiI5Y2X6KGXMjLomZ8!5e0!3m2!1szh-TW!2stw!4v1647961047192!5m2!1szh-TW!2stw",
    googleLink: "https://goo.gl/maps/QSXaKumRFyGo1CrL8",
    phone: "02-2397-1888",
    fbLink: "https://www.facebook.com/102863279043908",
    fbMessage: "https://m.me/102863279043908/",
    caseName: "仁愛旭",
    indigatorLength: 10,
    houseInfos: [
        ["投資興建", "中星建設股份有限公司"],
        ["建築設計", "黃明智建築師事務所"],
        ["公設設計", "源質室內裝修設計工程有限公司"],
        ["建築代銷", "聯碩地產股份有限公司"],
        ["建案位置", "台北市中正區紹興南街22號"],
        ["建照號碼", "110建字第0299號"],
        ["不動產經紀人", "徐福龍(91)北市經證字第00848號"],
    ],

    gtmCode: ["MMKBJ8C"], // 可放置多個
    recaptcha_site_key_v2: "6LfGUjEaAAAAANYvhZQx5imGm23pRt0V-rOvkpNC",
    recaptcha_site_key: "6Lck-L8UAAAAABxfvNA1NJuBrdl3iLFc3GkudC8s", // recaptcha v3
    recaptcha_user_token: "6Lck-L8UAAAAAIcvenwGDl8_Q1tKbrEYsKuriePa",
    order: {
        title: "預約賞屋",
        subTitle: "",
    },
};